import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './DevotionalManager.css'; // Create this file for custom styles

const DevotionalManager: React.FC = () => {
  const [devotionals, setDevotionals] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [form, setForm] = useState({
    id: '',
    date: '',
    title: '',
    objective: '',
    scripture: '',
    reflection: '',
    application: '',
    prayer: '',
    reflectionQuestions: '',
    goals: ''
  });

  useEffect(() => {
    const loginAndFetchDevotionals = async () => {
      try {
        // Call the login endpoint

        
        const loginResponse = await axios.get('https://api.faithnavigator.org/login'); // Adjust the URL if needed
        const { token } = loginResponse.data;

        // Save the token to localStorage
        localStorage.setItem('jwtToken', token);

        // Set the token in Axios headers for subsequent requests
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        // Fetch devotionals
        fetchDevotionals();
      } catch (error) {
        console.error('Error during login or fetching devotionals:', error);
      }
    };

    loginAndFetchDevotionals();
  }, []);

  const fetchDevotionals = async () => {
    try {
      const response = await axios.get('https://api.faithnavigator.org/devotionals'); // Adjust the endpoint if needed
      setDevotionals(response.data.devotionals);
    } catch (error) {
      console.error('Error fetching devotionals:', error);
    }
  };

  const bulkGenerateDevotionals = async () => {
    try {
      const response = await axios.post('https://api.faithnavigator.org/devotionals/bulkGenerate'); // Adjust the endpoint if needed
      console.log('Bulk generate response:', response.data);
      // Handle response as needed, e.g., update state or UI
    } catch (error) {
      console.error('Error generating devotionals in bulk:', error);
    }
  };
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const normalizeDate = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  }

  const onDateChange = (date) => {
    
    
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));

    //console.log(date)
    //console.log(utcDate)
    setSelectedDate(utcDate);
    //console.log(formatDateForCalendar(date)+" "+formatDate(date));
    
  //  setSelectedDate(date);
  const devotional = devotionals.find((d: any) => {
    const devotionalDate = new Date(d.date);
    //console.log(formatDate(devotionalDate)+" "+formatDate(d.date));
    
    return devotionalDate.getTime() === utcDate.getTime();
  });
    if (devotional) {
      handleEdit(devotional);
    } else {
      setForm({
        id: '',
        date: formatDate(utcDate),
        title: '',
        objective: '',
        scripture: '',
        reflection: '',
        application: '',
        prayer: '',
        reflectionQuestions: '',
        goals: ''
      });
    }
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Convert comma-separated strings to arrays
      const reflectionQuestionsArray = form.reflectionQuestions.split('|').map(question => question.trim());
      const goalsArray = form.goals.split('|').map(goal => goal.trim());

      const data = {
        ...form,
        reflectionQuestions: reflectionQuestionsArray,
        goals: goalsArray
      };

      if (form.id) {
        await axios.put(`https://api.faithnavigator.org/devotionals/${form.id}`, data); // Adjust the URL if needed
      } else {
        await axios.post('https://api.faithnavigator.org/devotionals', data); // Adjust the URL if needed
      }
      setForm({
        id: '',
        date: '',
        title: '',
        objective: '',
        scripture: '',
        reflection: '',
        application: '',
        prayer: '',
        reflectionQuestions: '',
        goals: ''
      });
      fetchDevotionals();
    } catch (error) {
      console.error('Error saving devotional:', error);
    }
  };

  const handleGenerateDevotional = async () => {
    try {
      const currentVerses = devotionals.map((devotional: any) => `${devotional.scripture}-${devotional.title}`);
      const response = await axios.post('https://api.faithnavigator.org/devotionals/generate', { currentVerses });
      const generatedDevotional = response.data;

      setForm({
        id: form.id,
        date: form.date,
        title: generatedDevotional.title,
        objective: generatedDevotional.objective,
        scripture: generatedDevotional.scripture,
        reflection: generatedDevotional.reflection,
        application: generatedDevotional.application,
        prayer: generatedDevotional.prayer,
        reflectionQuestions: generatedDevotional.reflectionQuestions.join('| '),
        goals: generatedDevotional.goals.join('| ')
      });
    } catch (error) {
      console.error('Error generating devotional:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate() + 1).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatDateForCalendar = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleEdit = (devotional) => {
    setForm({
      id: devotional.id,
      date: formatDate(devotional.date),
      title: devotional.title,
      objective: devotional.objective,
      scripture: devotional.scripture,
      reflection: devotional.reflection,
      application: devotional.application,
      prayer: devotional.prayer,
      reflectionQuestions: devotional.reflectionQuestions.map((q: any) => q.question).join('| '),
      goals: devotional.goals.map((g) => g.goal).join('| ')
    });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.faithnavigator.org/devotionals/${id}`); // Adjust the URL if needed
      fetchDevotionals();
    } catch (error) {
      console.error('Error deleting devotional:', error);
    }
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const utcDate = normalizeDate(date);
      
      if (devotionals.some((d) => new Date(d.date).getTime() === utcDate.getTime())) {
        return 'react-calendar__tile--highlight';
      }
    }
    return null;
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Devotional Manager</h1>

      <button className="btn btn-primary mb-4" onClick={handleGenerateDevotional}>
        Generate New Devotional
      </button>
      <button className="btn btn-primary mb-4" onClick={bulkGenerateDevotionals}>
        Bulk Generate
      </button>

      <Calendar
        onChange={onDateChange}
        value={selectedDate}
        tileClassName={tileClassName}
        
      />
      
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={form.id} />
        <div className="form-group">
          <label>ID: {form.id} </label><br/>
          <label htmlFor="date">Date: {formatDate(form.date)} </label>
          
          <input type="date" className="form-control" id="date" name="date" value={form.date} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input type="text" className="form-control" id="title" name="title" value={form.title} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="objective">Objective:</label>
          <input type="text" className="form-control" id="objective" name="objective" value={form.objective} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="scripture">Scripture:</label>
          <input type="text" className="form-control" id="scripture" name="scripture" value={form.scripture} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="reflection">Reflection:</label>
          <textarea className="form-control" id="reflection" name="reflection" value={form.reflection} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="application">Application:</label>
          <textarea className="form-control" id="application" name="application" value={form.application} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="prayer">Prayer:</label>
          <textarea className="form-control" id="prayer" name="prayer" value={form.prayer} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="reflectionQuestions">Reflection Questions (comma separated):</label>
          <input type="text" className="form-control" id="reflectionQuestions" name="reflectionQuestions" value={form.reflectionQuestions} onChange={handleInputChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="goals">Goals (comma separated):</label>
          <input type="text" className="form-control" id="goals" name="goals" value={form.goals} onChange={handleInputChange} required />
        </div>
        
        <button type="submit" className="btn btn-primary">{form.id ? 'Update' : 'Add'} Devotional</button>
      </form>
      
      <h2 className="mt-5">Devotional List</h2>
      <ul className="list-group">
        {devotionals.map((devotional) => (
          <li key={devotional.id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{devotional.title}</strong> - {devotional.date}
              </div>
              <div>
                <button className="btn btn-secondary btn-sm mr-2" onClick={() => handleEdit(devotional)}>Edit</button>
                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(devotional.id)}>Delete</button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DevotionalManager;
